import { Label } from '@fluentui/react';
import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoSizer,
  AutoSizerProps,
  CellMeasurer,
  CellMeasurerCache,
  CellMeasurerProps,
  Grid,
  GridCellProps,
  GridProps,
  ScrollParams,
} from 'react-virtualized';
import { v4 as uuid } from 'uuid';
import { IOnixTablePagination, IOnixTableRequest } from '../OnixTable/IOnixTable';
import { ListTileItem } from './ListTileItem';

const DEFAULT_COLUMN_WIDTH = 180;
const DEFAULT_ROW_HEIGHT = 180;
const FIRST_PAGE_NUMBER = 0;
const INITIAL_COLUM_NCOUNT = 5;

const CellMeasurerWrapper = CellMeasurer as unknown as React.ComponentType<CellMeasurerProps>;
const AutoSizerWrapper = AutoSizer as unknown as React.ComponentType<AutoSizerProps>;
const GridWrapper = Grid as unknown as React.ComponentType<GridProps>;

export interface ICommonListTilesProps {
  onClick?: (index: number | undefined, item: any) => void; // Function to execute when gallery image clicked.
  onRenderItem?: (index?: number, item?: any) => ReactNode;
}

export interface IListTilesProps {
  rowHeight?: number;
  columnWidth?: number;
  onSelectionChange?: (item: any[]) => void; // Function to execute when an image is selected.
  onClickItem?: (index: number | undefined, item: any) => void; // Function to execute when gallery image clicked.
  predicateToGetKey: (item: any) => any;
  onGetItems: (request: IOnixTableRequest) => Promise<IOnixTablePagination | undefined>;
  dependencyArrayToGetItems?: any[];
  isUpdateAllProperties?: boolean;
  onRenderEmpty?: () => ReactNode;
  onRenderItem?: (index?: number, item?: any) => ReactNode;
  disableSelection?: boolean;
}

export interface IOnixListTilesImperative {
  scrollToTop: () => void;
  selectItems: (keys: any[]) => void;
  getItem: (key: any) => any;
  getAllItems: () => any[];
  addNewItems: (items: any[]) => void;
  updateItems: (updateItems: any[], isAutoSelect?: boolean) => void;
  removeItems: (keys: any[]) => void;
  resetItems: () => void;
}

export const ListTiles = forwardRef((props: IListTilesProps, ref: React.Ref<IOnixListTilesImperative>) => {
  const [translate] = useTranslation();
  const [items, setItems] = useState<any[]>([]);
  const [columnWidth, setColumnWidth] = useState<number>(DEFAULT_COLUMN_WIDTH);
  const [rowHeight, setRowHeight] = useState<number>(DEFAULT_ROW_HEIGHT);

  const cache = new CellMeasurerCache({
    defaultWidth: columnWidth,
    fixedHeight: true,
  });

  // IMPORTANT: In the system we have situation to update, add, remove at the same time. So we needed base on RefObject.
  const itemsRef = useRef<any[]>([]);
  const isShiftPressedRef = useRef<boolean>(false);
  const anchorIndexRef = useRef<number | null>(null);
  const columnCountRef = useRef<number>(INITIAL_COLUM_NCOUNT);
  const gridRef = useRef<any>();

  useEffect(() => {
    if (props.columnWidth) {
      setColumnWidth(props.columnWidth);
    }
    if (props.rowHeight) {
      setRowHeight(props.rowHeight);
    }
  }, [props.columnWidth, props.rowHeight]);

  const [fetchMoreId, setFetchMoreId] = useState<string>();
  const [paginationInfo, setPaginationInfo] = useState<{ pageNumber: number; pageSize: number; totalItems: number; isLastPage: boolean }>({
    pageNumber: 0,
    totalItems: 0,
    pageSize: 0,
    isLastPage: false,
  });

  useImperativeHandle(ref, () => {
    const result: IOnixListTilesImperative = {
      scrollToTop,
      getAllItems,
      addNewItems,
      updateItems,
      removeItems,
      resetItems,
      getItem,
      selectItems,
    };
    return result;
  });

  const scrollToTop = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.scrollToCell({
        columnIndex: 0,
        rowIndex: 0,
      });
    }
  }, [gridRef]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      isShiftPressedRef.current = true;
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      isShiftPressedRef.current = false;
    }
  };

  useEffect(() => {
    if (fetchMoreId !== undefined && !paginationInfo.isLastPage) {
      if (props?.onGetItems) {
        const pageNumber = paginationInfo.pageNumber + 1;
        const request = { pageNumber: pageNumber } as IOnixTableRequest;
        props.onGetItems(request).then((res) => {
          if (res) {
            const onixTablePagination = Object.assign({}, res) as IOnixTablePagination;
            setPaginationInfo({ ...onixTablePagination });
            if (onixTablePagination.items) {
              itemsRef.current = [...itemsRef.current, ...onixTablePagination.items];
              setItems(itemsRef.current);
            }
          }
        });
      }
    }
  }, [fetchMoreId]);

  useEffect(() => {
    const request = { pageNumber: FIRST_PAGE_NUMBER } as IOnixTableRequest;
    props.onGetItems(request).then((res) => {
      if (res) {
        const onixTablePagination = Object.assign({}, res) as IOnixTablePagination;
        setPaginationInfo({ ...onixTablePagination });

        itemsRef.current = onixTablePagination.items || [];
        setItems(itemsRef.current);
        selectItems([]);
        if (props.onSelectionChange) props.onSelectionChange([]);
      }
    });
    scrollToTop();
  }, [props.dependencyArrayToGetItems]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const resetItems = () => {
    scrollToTop();
    setFetchMoreId(undefined);
    setItems([]);
    selectItems([]);
    itemsRef.current = [];
  };

  const getAllItems = useCallback(() => {
    return itemsRef.current.map((m) => Object.assign({}, m));
  }, [items]);

  const getItem = useCallback(
    (key: any) => {
      const itemByKey = itemsRef.current.find((m) => props.predicateToGetKey(m) === key);
      if (itemByKey) {
        return Object.assign({}, itemByKey);
      }
      return undefined;
    },
    [items]
  );

  const addNewItems = (items: any[]) => {
    if (items.length === 0) {
      return;
    }
    const newItems = [...items, ...itemsRef.current];

    setItems(newItems);

    if (itemsRef.current) {
      itemsRef.current = [...newItems];
    }
    if (paginationInfo.isLastPage) {
      setPaginationInfo({
        ...paginationInfo,
        totalItems: paginationInfo.totalItems + items.length,
      });
    }
  };

  const updateItems = async (updatingItems: any[], isAutoSelect: boolean = false) => {
    if (updatingItems.length === 0) {
      return;
    }
    const keys = updatingItems.map((m) => props.predicateToGetKey(m));
    normalUpdateItems(updatingItems);
    if (isAutoSelect) {
      setTimeout(() => {
        selectItems(keys);
      }, 200);
    }
  };

  const removeItems = (keys: any[]) => {
    if (keys.length > 0) {
      const items: any[] = [...itemsRef.current];
      const newItems = items.filter((m) => !keys.includes(props.predicateToGetKey(m)));

      if (itemsRef.current) {
        itemsRef.current = [...newItems];
      }

      setItems(newItems);
      selectItems([]);
      setPaginationInfo({
        ...paginationInfo,
        totalItems: paginationInfo.totalItems - keys.length,
      });
    }
  };

  const selectItems = (keys: any[]) => {
    const updatedItems = itemsRef.current.map((item) => {
      return { ...item, isSelected: keys.includes(props.predicateToGetKey(item)) };
    });
    itemsRef.current = updatedItems;
    setItems(updatedItems);
  };

  const normalUpdateItems = (updatingItems: any[]) => {
    if (updatingItems.length > 0) {
      const newItems = [...itemsRef.current];
      updatingItems.forEach((updateItem) => {
        const foundIndex = newItems.findIndex((m) => props.predicateToGetKey(m) === props.predicateToGetKey(updateItem));
        if (foundIndex >= 0) {
          if (props.isUpdateAllProperties) {
            newItems[foundIndex] = Object.assign({}, updateItem);
          } else {
            const newItem = { ...newItems[foundIndex] };
            Object.keys(updateItem).forEach((key) => {
              if (newItem[key] !== undefined) {
                newItem[key] = updateItem[key];
              }
            });
            newItems[foundIndex] = newItem;
          }
        }
      });

      setItems(newItems);

      if (itemsRef.current) {
        itemsRef.current = [...newItems];
      }
    }
  };

  const handleSelectionChange = useCallback(
    (index: number | undefined, item: any, checked?: boolean) => {
      if (props.disableSelection) return;

      if (isShiftPressedRef.current && anchorIndexRef.current !== null && index !== null) {
        const start = Math.min(anchorIndexRef.current, index ?? 0);
        const end = Math.max(anchorIndexRef.current, index ?? 0);
        const keysToSelect = itemsRef.current.slice(start, end + 1).map((item) => props.predicateToGetKey(item));
        selectItems(keysToSelect);
        props.onSelectionChange?.(itemsRef.current.filter((m) => m.isSelected));
      } else {
        anchorIndexRef.current = index ?? 0;
        const updatedItems = itemsRef.current.map((m) => {
          if (props.predicateToGetKey(m) === props.predicateToGetKey(item)) {
            return { ...m, isSelected: checked };
          }
          return m;
        });

        itemsRef.current = updatedItems;
        props.onSelectionChange?.(itemsRef.current.filter((m) => m.isSelected));
      }
    },
    [itemsRef, props.predicateToGetKey, props.onSelectionChange]
  );

  const cellRenderer = (gridCellProps: GridCellProps) => {
    const index = gridCellProps.rowIndex * columnCountRef.current + gridCellProps.columnIndex;
    if (index >= items.length) {
      return;
    }

    return (
      <CellMeasurerWrapper
        key={gridCellProps.key}
        cache={cache}
        parent={gridCellProps.parent}
        columnIndex={gridCellProps.columnIndex}
        rowIndex={gridCellProps.rowIndex}
      >
        {({ registerChild }) => (
          <div style={gridCellProps.style} className="tile" ref={registerChild as any}>
            <ListTileItem
              onClick={props.onClickItem}
              onRenderItem={props.onRenderItem}
              onSelect={handleSelectionChange}
              item={items[index]}
              index={index}
              disableSelection={props.disableSelection}
            />
          </div>
        )}
      </CellMeasurerWrapper>
    );
  };

  const onScrollEnd = useCallback((params: ScrollParams) => {
    if (params.scrollTop + params.clientHeight >= params.scrollHeight) {
      setFetchMoreId(uuid());
    }
  }, []);

  return (
    <div className="list-tiles-container">
      {items.length === 0 ? (
        <div className="empty-view">
          {props.onRenderEmpty ? props.onRenderEmpty() : <Label>{translate(`CommonResource.EmptyMessageHits`)}</Label>}
        </div>
      ) : (
        <div className="list">
          <AutoSizerWrapper>
            {({ width, height }) => {
              const newColumnCount = Math.floor(width / columnWidth);
              columnCountRef.current = newColumnCount;

              return (
                <GridWrapper
                  ref={gridRef}
                  onScroll={onScrollEnd}
                  cellRenderer={cellRenderer}
                  columnCount={newColumnCount}
                  columnWidth={columnWidth}
                  rowCount={Math.ceil(items.length / newColumnCount)}
                  rowHeight={rowHeight}
                  height={height}
                  width={width}
                  deferredMeasurementCache={cache}
                />
              );
            }}
          </AutoSizerWrapper>
        </div>
      )}
    </div>
  );
});
